/*--------------------------------------------------------------------- File Name: style.css ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- import Fonts ---------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,700i,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,800i&display=swap');

/*****---------------------------------------- 1) font-family: 'Rajdhani', sans-serif;
 2) font-family: 'Poppins', sans-serif;
 ----------------------------------------*****/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/

@import url(/css/animate.min.css);
@import url(/css/normalize.css);
@import url(/css/icomoon.css);
@import url(/css/font-awesome.min.css);
@import url(/css/meanmenu.css);
@import url(/css/owl.carousel.min.css);
/*@import url(/css/swiper.min.css);*/
@import url(/css/slick.css);
@import url(/css/jquery.fancybox.min.css);
@import url(/css/jquery-ui.css);
@import url(/css/nice-select.css);

/*--------------------------------------------------------------------- skeleton ---------------------------------------------------------------------*/

* {
     box-sizing: border-box !important;
}

html {
     scroll-behavior: smooth;
}

body {
     color: #666666;
     font-size: 14px;
     font-family: 'Poppins', sans-serif;
     line-height: 1.80857;
     font-weight: normal;
}

a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
     letter-spacing: 0;
     font-weight: normal;
     position: relative;
     padding: 0 0 10px 0;
     font-weight: normal;
     line-height: normal;
     color: #111111;
     margin: 0
}

h1 {
     font-size: 24px;
}

h2 {
     font-size: 18px;
}

h3 {
     font-size: 18px;
}

h4 {
     font-size: 16px
}

h5 {
     font-size: 14px
}

h6 {
     font-size: 13px
}

*,
*::after,
*::before {
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
     color: #212121;
     text-decoration: none!important;
     opacity: 1
}

button:focus {
     outline: none;
}

ul,
li,
ol {
     margin: 0px;
     padding: 0px;
     list-style: none;
}

p {
     margin: 0px;
     font-weight: 300;
     font-size: 15px;
     line-height: 24px;
}

a {
     color: #222222;
     text-decoration: none;
     outline: none !important;
}

a,
.btn {
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

img {
     max-width: 100%;
     height: auto;
}

 :focus {
     outline: 0;
}

.btn-custom {
     margin-top: 20px;
     background-color: transparent !important;
     border: 2px solid #ddd;
     padding: 12px 40px;
     font-size: 16px;
}

.lead {
     font-size: 18px;
     line-height: 30px;
     color: #767676;
     margin: 0;
     padding: 0;
}

.form-control:focus {
     border-color: #ffffff !important;
     box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
     border: none !important;
}

.badge {
     font-weight: 500;
}

blockquote {
     margin: 20px 0 20px;
     padding: 30px;
}

button {
     border: 0;
     margin: 0;
     padding: 0;
     cursor: pointer;
}

.full {
     float: left;
     width: 100%;
}

.full {
     width: 100%;
     float: left;
     margin: 0;
     padding: 0;
}


/**-- heading section --**/


/*---------------------------- preloader area ----------------------------*/

.loader_bg {
     position: fixed;
     z-index: 9999999;
     background: #fff;
     width: 100%;
     height: 100%;
}

.loader {
     height: 100%;
     width: 100%;
     position: absolute;
     left: 0;
     top: 0;
     display: flex;
     justify-content: center;
     align-items: center;
}

.loader img {
     width: 280px;
}


/*-- header area --*/


/*-- sidebar  navigation--*/

.sidepanel {
     width: 0;
     position: fixed;
     z-index: 9999;
     height: 100%;
     top: 0;
     left: 0;
     background-color: #070101;
     overflow-x: hidden;
     transition: 0.5s;
     padding-top: 60px;
}

.sidepanel a {
     padding: 8px 8px 8px 32px;
     text-decoration: none;
     font-size: 18px;
     color: #fff;
     display: block;
     transition: 0.3s;
}

.sidepanel a:hover {
     color: #0f98f8;
}

.sidepanel .closebtn {
     position: absolute;
     top: 0;
     right: 25px;
     font-size: 36px;
}

.openbtn {
     font-size: 20px;
     cursor: pointer;
     background-color: transparent;
     padding: 0;
     border: none;
     margin-left: 16px;
}

.openbtnMobile {
     display: none;
}

/*--      end sidebar  navigation--*/

.logo {
     padding-top: 12px;
}

ul.conat_info li {
     display: inline-block;
     padding: 0 26px;
}

ul.conat_info li a {
     color: #fff;
     display: inline-block;
     font-size: 30px;
}

ul.conat_info li a:hover {
     color: #fff;
}

.right_bottun {
     float: right;
     display: flex;
     flex-wrap: wrap;
}

.header {
     width: 100%;
     padding: 30px 30px;
     background: transparent;
     position: absolute;
     z-index: 999;
}


/** banner section **/
@media (min-width: 992px) {
     #banner_main_md {
          display: none;
     }     
}

.banner_main {
     background: url(../images/banner.png);
     padding: 300px 0px 0px 0px;
     background-size: auto 76%;
     background-repeat: no-repeat;
     min-height: 1150px;
     background-position: right top;
}

.text-bg {
     margin-top: 70px;
     text-align: left;
     float: right;
     max-width: 430px;
     width: 100%;
}

.text-bg h1 {
     color: #0f98f8;
     font-family: "Oswald", sans-serif;
     font-optical-sizing: auto;
     font-size: 60px;
     line-height: 70px;
     padding-bottom: 45px;
}

.text-bg span {
     color: #222222;
     font-weight: 300;
     font-size: 19px;
     line-height: 30px;
     margin-bottom: 30px;
     display: block;
}

.ban_track {
     margin-top: 40px;
}

.banner_slide {
     padding: 0 80px;
}

.ban_track figure {
     margin: 0;
}

.transfot {
     text-align: left;
}

.transfot span {
     color: #fff;
     font-size: 17px;
}

.transfot h3 {
     color: #fff;
     font-size: 23px;
     line-height: 30px;
     font-weight: 300;
     margin-bottom: 16px;
}

.transfot_form {
     padding: 0 15px;
     height: 40px;
     margin-bottom: 15px;
     border: #fff solid 1px;
     font-size: 16px;
}

.get_now {
     /*max-width: 54%;
     width: 100%;*/
     padding: 0px 15px;
     height: 40px;
     background: #222b3c;
     color: #fff;
     font-size: 17px;
}

.get_now:hover {
     background-color: #000;
}

#banner1 .carousel-caption {
     position: inherit;
     padding: 0;
}

#banner1 .carousel-indicators {
     display: none;
}

#banner1 .carousel-control-prev,
#banner1 .carousel-control-next {
     width: 45px;
     height: 45px;
     background: transparent;
     opacity: 1;
     font-size: 30px;
     color: #fff;
     border-radius: 40px;
     border: #fff solid 2px;
}

#banner1 .carousel-control-prev:hover,
#banner1 .carousel-control-next:hover,
#banner1 .carousel-control-prev:focus,
#banner1 .carousel-control-next:focus {
     background: #000;
     color: #fff;
}

#banner1 a.carousel-control-prev {
     position: absolute;
     left: inherit;
     top: 35%;
     right: 16px;
}

#banner1 a.carousel-control-next {
     position: absolute;
     right: 16px;
     bottom: 10px;
     top: 50%;
}


/** end banner section **/

.titlepage h2 {
     font-size: 50px;
     color: #0f98f8;
     line-height: 45px;
     font-weight: normal;
     padding: 0;
}

.d_flex {
     display: flex;
     align-items: center;
     flex-wrap: wrap;
}

.read_more {
     font-size: 20px;
     background-color: transparent;
     color: #262626 !important;
     padding: 13px 0px;
     width: 100%;
     max-width: 198px;
     font-weight: 500;
     position: relative;
     display: block;
     line-height: 1px;
}

.read_more::before {
     position: absolute;
     content: "";
     border-right: #262626 solid 80px;
     width: 200px;
     height: 1px;
     line-height: 1px;
}


/** about section **/

.about {
     background-color: #f7f7f9;
     margin-top: 80px;
     padding: 90px 0;
}

.about .about_img figure {
     margin: 0;
}

.about .about_img figure img {
     width: 100%;
}

.about .titlepage p {
     padding: 30px 0px;
     color: #202121;
     font-size: 17px;
     line-height: 32px;
     font-weight: 300;
     display: inline-block;
}


/** end about section **/


/** service section **/

.service {
     background-color: #fff;
     margin-top: 80px;
     padding: 90px 0;
}

.service .titlepage {
     text-align: center;
     padding-bottom: 30px;
}

.service .titlepage p {
     color: #0c0202;
     font-size: 17px;
     line-height: 30px;
     padding-top: 20px;
}

.service_main {
     display: flex;
     flex-wrap: wrap;
}

.service_box {
     text-align: center;
     width: 16.3%;
     margin-top: 30px !important;
}

.service_box i {
     width: 181px;
     display: block;
     height: 181px;
     border-radius: 200px;
}

.service_box i img {
     text-align: center;
     padding-top: 33%;
}

.service_box h4 {
     color: #494949;
     font-size: 17px;
     margin-top: 25px;
     padding: 0;
     font-weight: 300;
}

.service_box h4:hover {
     color: #0f98f8;
}

.blu_colo i {
     background-color: #0f98f8;
}

.babyblue_colo i {
     background-color: #0070FF;
}

.yelldark_colo {
     margin: 0 50px;
}

.yelldark_colo i {
     background-color: #0f98f8 !important;
}

.service .read_more {
     margin: 0 auto;
     padding-top: 70px;
}


/** end service section **/


/** vehicles section **/

.vehicles {
     background-color: #fff;
     padding: 70px 0 155px 0;
}

.vehicles .titlepage {
     text-align: center;
     padding-bottom: 60px;
}

.vehicles .titlepage p {
     color: #0d0202;
     padding-top: 15px;
     text-align: center;
     margin: 0 50px;
     font-size: 17px;
     font-weight: 400;
}

.vehicles_truck {
     background-color: #efedf0;
     transition: ease-in all 0.5s;
     width: 100%;
     border-radius: 25px;
     padding: 35px 0;
}

.vehicles_truck:hover {
     background-color: #fff;
     transition: ease-in all 0.5s;
     box-shadow: 0 0 16px rgba(22, 22, 23, 15%)
}

.vehicles_truck figure {
     margin: 0;
}

.blac_co {
     color: #000;
     font-size: 25px;
     line-height: 20px;
     font-weight: bold;
     text-transform: uppercase;
     margin-top: 34px;
}

.vehicles .read_more {
     margin-top: 0px;
     margin: 0 auto;
}

#veh .carousel-caption {
     position: inherit;
     padding: 0;
}

#veh .carousel-indicators {
     display: none;
}

#veh .carousel-control-prev,
#veh .carousel-control-next {
     width: 50px;
     height: 50px;
     background: #efedf0;
     opacity: 1;
     font-size: 30px;
     color: #000;
     border-radius: 5px;
     top: 111%;
     z-index: 9999;
}

#veh .carousel-control-prev:hover,
#veh .carousel-control-next:hover,
#veh .carousel-control-prev:focus,
#veh .carousel-control-next:focus {
     background: #0f98f8;
     color: #fff;
}

#veh a.carousel-control-prev {
     position: absolute;
     left: 46%;
}

#veh a.carousel-control-next {
     position: absolute;
     right: 46%;
     bottom: 10px;
}

.vehicles .read_more {
     margin: 0 auto;
     padding-top: 150px;
}


/** end vehicles section **/


/** testimonial section **/

.testimonial {
     padding: 90px 0 90px 0;
     background-color: #0f98f8;
     border-radius: 50px;
}

.testimonial .titlepage {
     text-align: center;
     padding-bottom: 60px;
}

.testimonial .titlepage h2 {
     color: #fff;
}

.testimonial .titlepage p {
     color: #fff;
     font-size: 17px;
     line-height: 24px;
     font-weight: 500;
     padding-top: 20px;
}

.testimonial_box h3 {
     color: #fff;
     font-size: 25px;
     line-height: 36px;
     font-weight: 600;
     padding-top: 26px;
     text-align: left;
     padding-left: 20px;
     display: table-cell;
}

.testimonial_box p {
     color: #fff;
     font-size: 17px;
     line-height: 32px;
     padding-top: 30px;
     display: inline-block;
     text-align: left;
     font-weight: 400;
}

.kisu {
     font-size: 17px;
}

.testimonial_Carousel .carousel-caption {
     position: inherit;
     padding: 0;
}

.testimonial_box figure {
     margin: 0;
     float: left;
}

#myCarousel .carousel-indicators {
     display: none;
}

#myCarousel .carousel-control-prev,
#myCarousel .carousel-control-next {
     width: 50px;
     height: 50px;
     background: #efedf0;
     opacity: 1;
     font-size: 30px;
     color: #000;
     border-radius: 5px;
     z-index: 9999;
}

#myCarousel .carousel-control-prev:hover,
#myCarousel .carousel-control-next:hover,
#myCarousel .carousel-control-prev:focus,
#myCarousel .carousel-control-next:focus {
     color: #0f98f8;
}

#myCarousel a.carousel-control-prev {
     position: absolute;
     left: inherit;
     right: -5%;
     top: 32%;
}

#myCarousel a.carousel-control-next {
     position: absolute;
     right: -5%;
     bottom: 10px;
     top: 52%;
}

.testimonial .read_more::before {
     border-right: #fff solid 80px;
}

.testimonial .read_more {
     position: relative;
     color: #fff !important;
     margin-top: 50px;
     padding-left: 15px;
}


/** end testimonial section **/


/** choose section **/
.choose {
     background-color: #f7f7f9;
     margin-top: 80px;
     padding: 90px 0;
}

.choose .titlepage {
     text-align: center;
     padding-bottom: 60px;
}

.choose_box i {
     float: left;
     padding-right: 20px;
}

.choose_box h3 {
     padding-top: 46px;
     color: #0f98f8;
     font-weight: normal;
     font-size: 25px;
     line-height: 30px;
}

.choose_box p {
     display: inline-block;
     color: #202121;
     font-size: 17px;
     line-height: 30px;
     padding: 10px 0 70px 0;
}


/** end choose section **/


/** contact section **/

.contact {
     background-color: #fff;
     padding: 90px;
}

.contact .titlepage {
     text-align: center;
     padding-bottom: 60px;
}

.main_form {
     background-color: #fff;
     border-radius: 20px;
     max-width: 426px;
     width: 100%;
     float: right;
}

.main_form .contactus {
     border: #cce9fe solid 1px;
     border-radius: 10px;
     margin-bottom: 25px;
     width: 100%;
     height: 60px;
     background: transparent;
     border-color: #0f98f8;
     color: #8f8d8d;
     font-size: 18px;
     font-weight: normal;
     padding: 0 15px;
}

.main_form .contactusmess {
     border: #cce9fe solid 1px;
     border-radius: 10px;
     padding: 0 15px;
     margin-bottom: 25px;
     width: 100%;
     padding-top: 9px;
     padding-bottom: 14px;
     background: transparent;
     border-color: #0f98f8;
     color: #8f8d8d;
     font-size: 18px;
     font-weight: normal;
     height: 168px;
}

.send_btn {
     background: #0f98f8;
     font-weight: 500;
     font-size: 18px;
     width: 198px;
     color: #fff;
     height: 57px;
     margin-top: 20px !important;
     transition: ease-in all 0.5s;
     text-transform: uppercase;
     border-radius: 30px;
     margin: 0 auto;
     display: block;
}

.send_btn:hover {
     background: #202121;
     transition: ease-in all 0.5s;
}

#request *::placeholder {
     color: #959596;
     opacity: 1;
}

.co_tru {
     padding-right: 50px;
}

.co_tru figure {
     margin: 0;
}

.co_tru figure img {
     width: 100%;
}


/** end contact section **/


/** footer **/

.footer {
     background: #f7f7f9;
     /*margin-top: 90px;*/
     padding-top: 40px;
     text-align: center;
}

ul.location_icon {
     padding-top: 12px;
}

ul.location_icon li {
     font-size: 18px;
     line-height: 30px;
     color: #202121;
     font-weight: 300;
     text-align: left;
     padding-bottom: 40px;
     float: left;
     display: flex;
     align-items: center;
}

ul.location_icon li a {
     font-size: 30px;
     color: #0f98f8;
     line-height: 40px;
     padding-right: 16px;
     float: left;
}

ul.location_icon li a:hover {
     color: #353535;
}

ul.social_icon li a {
     font-size: 20px;
     color: #0f98f8;
     line-height: 40px;
     padding-right: 16px;
     float: left;
}

ul.social_icon li a:hover {
     color: #353535;
}

.map {
     margin-right: -50px;
}

.map figure {
     margin: 0;
}

.map figure img {
     margin-left: 25px;
     width: 80%;
}

.bottom_form {
     margin-top: 50px;
     float: left;
     width: 100%;
}

.bottom_form h3 {
     font-weight: 600;
     font-size: 20px;
     text-align: left;
     color: #353535;
     padding-bottom: 15px;
}

.bottom_form .enter {
     border: inherit;
     padding: 0px 19px;
     width: 66%;
     height: 49px;
     background: #fff;
     color: #000;
     font-size: 16px;
     font-weight: 500;
     float: left;
}

.sub_btn {
     font-size: 16px;
     transition: ease-in all 0.5s;
     background-color: #0f98f8;
     color: #fff;
     padding: 10px 0px;
     max-width: 105px;
     width: 100%;
     display: block;
     font-weight: 600;
     margin-left: 0px;
}

.sub_btn:hover {
     background-color: #000;
     transition: ease-in all 0.5s;
     color: #fff;
}

.copyright {
     background-color: #0f98f8;
     margin-top: 40px;
     padding: 20px 0;
}

.copyright p {
     color: #fff;
     font-size: 16px;
     line-height: 22px;
     text-align: center;
     padding-top: 0px;
     font-weight: 300;
     padding-right: 0 !important;
}

.copyright a {
     color: #fff;
}

.copyright a:hover {
     color: #000;
}


/** end footer **/

/** translator **/

.gt_float_switcher .gt-selected {
     position: relative;
     z-index: 888;
     border-radius: 10px !important;
     cursor: pointer;
     text-transform: uppercase;
     overflow: hidden;
     text-align: left;
 }

.gt_float_switcher .gt-selected .gt-current-lang {
     padding: 6px 10px;
     font-weight: normal !important;
     font-size: 16px !important;
 }

 .gt_float_switcher img {
     vertical-align: middle;
     display: inline-block;
     width: 25px !important;
     /* height: auto; */
     margin: 0 5px 0 0;
     border-radius: 3px;
 }